@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-Regular.ttf') format('truetype');
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 1em;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
    list-style-type: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* General Styles */

body {
    background: #fff;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    ;*/
    font-family: 'Lato', sans-serif !important;
    font-size: 0.9em;
    color: #000;
    line-height: normal;
}

h1 {
    font-size: 2.5em;
    font-weight: 700;
    line-height: 1.1em;
}

h2 {
    font-size: 1.7em;
    line-height: 1.1em;
}

h3 {
    font-size: 1.8em;
}

h4 {
    font-size: 1.5em;
}

h2,
h3 {
    font-weight: 400;
}

@media (max-width: 768px) {
    h1 {
        font-size: 1.7em;
    }

    h2 {
        font-size: 1.3em;
    }

    h3 {
        font-size: 1.2em;
    }

    h4 {
        font-size: 1.1em;
    }
}

p {
    font-size: 1em;
}

small {
    font-size: 0.9em;
}

a {
    color: #000;
    text-decoration: none;
}

strong {
    font-size: 1.2em;
    font-weight: 800;
}

b {
    font-weight: 800;
}

i {
    font-size: 1.5em;
    line-height: 1em;
    vertical-align: -7.5px;
}

hr {
    border-color: #000;
    border-style: solid;
}

.row {
    margin: 0;
    padding: 0;
}

.no-scroll {
    overflow: hidden;
}

[class*=col-] {
    padding: 0;
    margin: 0;
}

.fa {
    font-size: 2em !important;
}
